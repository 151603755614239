export const config = {
  apiUrl: "https://localhost:5001",
  // apiUrl: "https://plateline-hub-api-dev.azurewebsites.net",
  devApiUrl: "https://plateline-hub-api-dev.azurewebsites.net",
  prodApiUrl: "https://plateline-hub-api-prod.azurewebsites.net",
  authRoles: {
    sa: ["SA"], // Only Super Admin has access
    admin: ["SA", "Admin"], // Only SA & Admin has access
    editor: ["SA", "Admin", "Editor"], // Only SA & Admin & Editor has access
    user: ["SA", "Admin", "Editor", "User"], // Only SA & Admin & Editor & User has access
    guest: ["SA", "Admin", "Editor", "User", "Guest"], // Everyone has access
  },
  syncFusionLicense: "Mgo+DSMBPh8sVXJ3S0d+X1dPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXdRckdiXHtaeXRUQGM=;Mgo+DSMBMAY9C3t2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adEFhWHpadH1VRGlf;MjcxOTk0NkAzMjMzMmUzMDJlMzBld1BGbnpoKzFrSEZNUmJYSzJ2cHo3UU9YNTBiTnVXN3lkNXFhRWlhNVlrPQ=="
};
